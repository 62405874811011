import React, { useRef } from "react";
import PropTypes from "prop-types";
import ButtonWithIcon from "components/ButtonWithIcon";
import Tooltip from "components/Tooltip";
import ModalCarousel from "components/ModalCarousel";
import useClickOutsideListener from "utils/useClickOutsideListener";
import { PICKUP_INCIDENTS, PICKUP_STATUS } from "../usePicking";

const StatusIndicator = ({ status, incidentReason }) => {
  const getStatusIcon = () => {
    if (status === PICKUP_STATUS.PICKUP_OK.value) {
      return <i className="fas fa-check fa-lg" />;
    }
    if (status === PICKUP_STATUS.PICKUP_FAILED.value) {
      return <i className="fas fa-times fa-lg" />;
    }
    return null;
  };

  return (
    <div className="picking-card__status">
      {getStatusIcon()}
      {incidentReason && PICKUP_INCIDENTS[incidentReason] && (
        <Tooltip text={PICKUP_INCIDENTS[incidentReason].label}>
          <i className="fas fa-exclamation-circle fa-lg" />
        </Tooltip>
      )}
    </div>
  );
};

StatusIndicator.propTypes = {
  status: PropTypes.string,
  incidentReason: PropTypes.string,
};

const PhotosSection = ({ photos, handleDeletePhoto, alreadySynchronized }) => {
  if (!photos?.length) return null;

  return (
    <ModalCarousel
      photos={photos}
      handleDeletePhoto={handleDeletePhoto}
      disableDeletion={alreadySynchronized}
    >
      <i className="fas fa-camera fa-lg" />
    </ModalCarousel>
  );
};

PhotosSection.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    base64: PropTypes.string.isRequired,
  })),
  handleDeletePhoto: PropTypes.func.isRequired,
  alreadySynchronized: PropTypes.bool.isRequired,
};

const IncidentButton = ({
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
  metadataPath,
}) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const wrapperRef = React.useRef();
  useClickOutsideListener(wrapperRef, () => setMenuIsOpen(false));

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div ref={wrapperRef} className="incident-button">
      <ButtonWithIcon
        className="declare-incident-button"
        onClick={toggleMenu}
        icon="chevron-down"
        iconPosition="right"
      >
        Incident
      </ButtonWithIcon>
      {menuIsOpen && (
        <div className="incident-button__menu">
          <div
            className="incident-button__menu-item"
            onClick={() => {
              handleDamagedPackagingOrProductPhoto();
              setMenuIsOpen(false);
            }}
          >
            Emballage/Produit abîmé
          </div>
          <div
            className="incident-button__menu-item"
            onClick={() => {
              updatePickupIncidentReason(
                metadataPath,
                PICKUP_INCIDENTS.MISSING_PRODUCT.value,
                false
              );
              setMenuIsOpen(false);
            }}
          >
            Produit Manquant
          </div>
          <div
            className="incident-button__menu-item"
            onClick={() => {
              updatePickupIncidentReason(
                metadataPath,
                PICKUP_INCIDENTS.PRODUCT_OR_EAN_DOES_NOT_MATCH.value,
                false
              );
              setMenuIsOpen(false);
            }}
          >
            Modèle/EAN ne correspond pas
          </div>
        </div>
      )}
    </div>
  );
};

IncidentButton.propTypes = {
  updatePickupIncidentReason: PropTypes.func.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
  metadataPath: PropTypes.string.isRequired,
};

const PickingArticle = ({
  article,
  metadata,
  handleScan,
  handleTakePhoto,
  handleDeletePhoto,
  resetPickupStatus,
  handleSuccessfulPickup,
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
  canTakePhoto,
  onTakePhotoSuccessful,
  setCurrentArticleMetadataPath,
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let quality = 1.0;
        let result;
        do {
          result = canvas.toDataURL("image/jpeg", quality);
          quality -= 0.05;
        } while (quality > 0 && result.length > 100 * 1024);

        onTakePhotoSuccessful(result);
      };
    };
    reader.readAsDataURL(file);
  };

  const handleAddFromGalleryClick = () => {
    setCurrentArticleMetadataPath(metadata.path);
    fileInputRef.current?.click();
  };

  return (
    <div className="picking-card">
      <div className="picking-card__header">
        <div className="picking-card__title">
          <h3>{article.name}</h3>
          <span className="picking-card__ean">EAN: {article?.ean || "N/A"}</span>
        </div>
        <StatusIndicator
          status={metadata.pickup_status}
          incidentReason={metadata.pickup_incident_reason}
        />
      </div>

      <div className="picking-card__info">
        <div className="picking-card__info-item">
          <span className="label">Nombre de colis:</span>
          <span className="value">{article?.numberOfPackages || "?"}</span>
        </div>
        <div className="picking-card__info-item">
          <span className="label">Poids:</span>
          <span
            className="value"
            style={{ color: article?.weight > 120 ? "red" : "inherit" }}
          >
            {article?.weight || "?"} kg
          </span>
        </div>
      </div>

      <div className="picking-card__photos">
        <PhotosSection 
          photos={metadata.pickup_photos}
          handleDeletePhoto={handleDeletePhoto}
          alreadySynchronized={metadata.alreadySynchronized}
        />
      </div>

      {!metadata.alreadySynchronized && (
        <div className="picking-card__actions">
          {metadata.pickup_status === PICKUP_STATUS.PICKUP_OK.value ? (
            <ButtonWithIcon
              disabled={metadata.pickup_incident_reason}
              className="cancel-pickup-button"
              onClick={() => resetPickupStatus(false)}
              icon="times"
            >
              Annuler
            </ButtonWithIcon>
          ) : (
            <>
              {article.ean ? (
                <ButtonWithIcon
                  disabled={metadata.pickup_incident_reason}
                  onClick={handleScan}
                  icon="barcode"
                >
                  Scan EAN
                </ButtonWithIcon>
              ) : (
                <ButtonWithIcon
                  disabled={metadata.pickup_incident_reason}
                  onClick={handleSuccessfulPickup}
                  icon="hand-paper"
                >
                  Enlever
                </ButtonWithIcon>
              )}
            </>
          )}

          {metadata.pickup_incident_reason ? (
            <button
              className="reset-pickup-incident-button"
              onClick={() => resetPickupStatus(true)}
            >
              Annuler incident
            </button>
          ) : (
            <IncidentButton
              updatePickupIncidentReason={updatePickupIncidentReason}
              handleDamagedPackagingOrProductPhoto={handleDamagedPackagingOrProductPhoto}
              metadataPath={metadata.path}
            />
          )}

          <ButtonWithIcon
            disabled={!canTakePhoto}
            icon="camera"
            onClick={handleTakePhoto}
          >
            Photo
          </ButtonWithIcon>

          <ButtonWithIcon
            disabled={!canTakePhoto}
            icon="folder-open"
            onClick={handleAddFromGalleryClick}
          >
            Importer
          </ButtonWithIcon>

          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </div>
      )}
    </div>
  );
};

PickingArticle.propTypes = {
  article: PropTypes.shape({
    name: PropTypes.string.isRequired,
    ean: PropTypes.string,
    numberOfPackages: PropTypes.number,
    weight: PropTypes.number, // Added weight prop
  }).isRequired,
  metadata: PropTypes.shape({
    path: PropTypes.string.isRequired,
    pickup_status: PropTypes.string,
    pickup_incident_reason: PropTypes.string,
    pickup_photos: PropTypes.arrayOf(PropTypes.shape({
      base64: PropTypes.string.isRequired,
    })),
    alreadySynchronized: PropTypes.bool.isRequired,
  }).isRequired,
  handleScan: PropTypes.func.isRequired,
  handleTakePhoto: PropTypes.func.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  resetPickupStatus: PropTypes.func.isRequired,
  handleSuccessfulPickup: PropTypes.func.isRequired,
  updatePickupIncidentReason: PropTypes.func.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
  canTakePhoto: PropTypes.bool.isRequired,
  onTakePhotoSuccessful: PropTypes.func.isRequired,
  setCurrentArticleMetadataPath: PropTypes.func.isRequired,
};

export default PickingArticle;
