import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const PhotosInput = ({ disabled, label, photoList, setPhotoList }) => {
  const [isSnapping, setIsSnapping] = useState(false);
  const [isCameraLoading, setIsCameraLoading] = useState(false);
  const [cameraStream, setCameraStream] = useState(null);

  const realPhotoLength = photoList.filter((photo) => !photo.remove).length;

  const addPhoto = async () => {
    if (disabled || realPhotoLength >= 20) return;

    setIsSnapping(true);
    setIsCameraLoading(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      setCameraStream(stream);
      setIsCameraLoading(false);
    } catch (error) {
      console.error("Error accessing camera:", error);
      setIsSnapping(false);
      setIsCameraLoading(false);

      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        alert(
          "Camera access was denied. Please enable camera permissions in your browser settings."
        );
      } else {
        alert("An error occurred while accessing the camera.");
      }
    }
  };

  const takePicture = () => {
    if (!cameraStream) return;

    const camera = document.getElementById("camera");
    const canvas = document.createElement("canvas");
    const videoWidth = camera.videoWidth;
    const videoHeight = camera.videoHeight;

    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(camera, 0, 0, videoWidth, videoHeight);

    let photoData = canvas.toDataURL("image/jpeg", 0.75);

    // Compress the image to ensure it's under 20KB
    let quality = 0.75;
    while (photoData.length > 100000 && quality > 0.1) {
      quality -= 0.05;
      photoData = canvas.toDataURL("image/jpeg", quality);
    }

    setPhotoList([
      ...photoList,
      {
        id: Date.now(),
        src: photoData,
        new: true,
      },
    ]);

    stopCamera();
    setIsSnapping(false);
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
    }
  };

  const cancelPicture = () => {
    stopCamera();
    setIsSnapping(false);
  };

  const removePaymentPhoto = (photoId) => {
    setPhotoList((prevList) =>
      prevList.map((photo) =>
        photo.id === photoId ? { ...photo, remove: true } : photo
      )
    );
  };

  const unremovePaymentPhoto = (photoId) => {
    setPhotoList((prevList) =>
      prevList.map((photo) =>
        photo.id === photoId ? { ...photo, remove: false } : photo
      )
    );
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    if (realPhotoLength + files.length > 20) {
      alert(
        "Vous pouvez sélectionner jusqu'à 20 photos. Supprimez d'abord une photo."
      );
      return;
    }

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Once FileReader finishes, load the result into an <img> to compress
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // Try compressing from 1.0 down by 0.05 until size <= 100 KB
          let currentQuality = 1.0;
          const step = 0.05;
          let compressedResult;
          do {
            compressedResult = canvas.toDataURL("image/jpeg", currentQuality);
            const sizeInBytes = Math.floor(compressedResult.length);
            if (sizeInBytes <= 100 * 1024) {
              // 100 KB
              break;
            }
            currentQuality -= step;
          } while (currentQuality > 0);

          setPhotoList((prevList) => [
            ...prevList,
            {
              id: Math.floor(Date.now() + Math.random() * 1000),
              src: compressedResult,
              new: true,
            },
          ]);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const hasPhotos = photoList.length > 0;

  return (
    <section className="photo-input">
      {label}
      <section className="photos-section">
        <h3>{hasPhotos && <small>( {realPhotoLength} / 20 )</small>}</h3>
        <ul className="photos-list">
          {photoList.map((photo, index) => (
            <li
              key={photo.id}
              className={classnames(
                "photo-item",
                photo.remove && "-remove",
                photo.removed && "-removed"
              )}
            >
              <div className="photo-holder">
                {photo.removed ? (
                  "Photo supprimée"
                ) : (
                  <img
                    src={photo.src}
                    alt={`pic ${index + 1}`}
                    className="photo"
                  />
                )}
              </div>
              {!disabled && photo.new && !photo.remove && (
                <button
                  className="button delete"
                  onClick={(event) => {
                    event.preventDefault();
                    removePaymentPhoto(photo.id);
                  }}
                >
                  Supprimer la photo
                </button>
              )}
              {!disabled && photo.new && photo.remove && (
                <button
                  className="button undelete"
                  onClick={(event) => {
                    event.preventDefault();
                    if (realPhotoLength >= 20) {
                      alert(
                        "Vous avez déjà 20 photos sélectionnées. Supprimez d'abord une photo."
                      );
                    } else {
                      unremovePaymentPhoto(photo.id);
                    }
                  }}
                >
                  Annuler la suppression
                </button>
              )}
            </li>
          ))}
          {!disabled && realPhotoLength < 20 && (
            <>
              <li className="photo-item">
                <button
                  className="photo addPhotoButton"
                  onClick={(event) => {
                    event.preventDefault();
                    addPhoto();
                  }}
                >
                  <h2>+</h2>
                  Prendre la photo
                </button>
              </li>
              <li className="photo-item">
                <label className="gallery">
                  Ajouter depuis la galerie
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </label>
              </li>
            </>
          )}
        </ul>
        {isSnapping && (
          <div className="photo-snapper">
            {isCameraLoading ? (
              <h3 className="loading">Chargement...</h3>
            ) : (
              <>
                <video
                  className="cameraView"
                  id="camera"
                  autoPlay
                  playsInline
                  muted
                  ref={(video) => {
                    if (video && cameraStream) {
                      video.srcObject = cameraStream;
                    }
                  }}
                />
                <button className="takePictureButton" onClick={takePicture}>
                  Prendre la photo
                </button>
                <button className="cancelButton" onClick={cancelPicture}>
                  Annuler
                </button>
              </>
            )}
          </div>
        )}
      </section>
    </section>
  );
};

PhotosInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  photoList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      src: PropTypes.string.isRequired,
      new: PropTypes.bool,
      remove: PropTypes.bool,
      removed: PropTypes.bool,
    })
  ).isRequired,
  setPhotoList: PropTypes.func.isRequired,
};

export default PhotosInput;
