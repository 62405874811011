import React from "react";
import PropTypes from "prop-types";
import Accordion from "components/Accordion";
import ModalCalendar from "components/ModalCalendar";
import HalfdaySwitch from "components/HalfdaySwitch";
import ButtonWithIcon from "components/ButtonWithIcon";
import getRemainingOrdersNumber from "utils/getRemainingOrdersNumber";
import { format } from "utils/date";
import OrderItem from "../OrderItem";

const PickingList = ({
  setAdditionalContractorsDate,
  currentPickingInfos,
  handleScan,
  handleTakePhoto,
  handleDeletePhoto,
  resetPickupStatus,
  handleSuccessfulPickup,
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
  updateOrderComment,
  additionalContractorsDate,
  contractor,
  usedDates,
  setCurrentArticleMetadataPath,
  onTakePhotoSuccessful,
  startXscan,
}) => {
  const [calendarOpening, setCalendarOpening] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(null);
  const [selectedHalfday, setSelectedHalfday] = React.useState("am");
  const [activeOrderItem, setActiveOrderItem] = React.useState(null);

  return (
    <div className="picking__list">
      <ButtonWithIcon icon="plus" onClick={() => setCalendarOpening(true)}>
        Ajouter une date
      </ButtonWithIcon>
      {currentPickingInfos.map((orderPickings, index) => {
        const title = (
          <div className="accordion-title">
            <div className="accordion-title__date">
              {new Date(orderPickings.date).toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </div>
            <div className="accordion-title__remaining-order">
              <span>
                Matin:
                {` ${getRemainingOrdersNumber(orderPickings.am)} / ${
                  orderPickings.am.length
                }`}
              </span>
              <span>
                Après-midi:
                {` ${getRemainingOrdersNumber(orderPickings.pm)} / ${
                  orderPickings.pm.length
                }`}
              </span>
            </div>
          </div>
        );

        const content = (
          <>
            <HalfdaySwitch
              selectedHalfday={selectedHalfday}
              setSelectedHalfday={setSelectedHalfday}
            />
            {orderPickings[selectedHalfday].length > 0
              ? orderPickings[selectedHalfday].map((order) => (
                  <OrderItem
                    key={order.id}
                    order={order}
                    handleScan={handleScan}
                    handleTakePhoto={handleTakePhoto}
                    handleDeletePhoto={handleDeletePhoto}
                    activeOrderItem={activeOrderItem}
                    setActiveOrderItem={setActiveOrderItem}
                    resetPickupStatus={resetPickupStatus}
                    handleSuccessfulPickup={handleSuccessfulPickup}
                    updatePickupIncidentReason={updatePickupIncidentReason}
                    handleDamagedPackagingOrProductPhoto={
                      handleDamagedPackagingOrProductPhoto
                    }
                    handleAddCommentToOrder={updateOrderComment}
                    setCurrentArticleMetadataPath={
                      setCurrentArticleMetadataPath
                    }
                    onTakePhotoSuccessful={onTakePhotoSuccessful}
                    startXscan={startXscan}
                  />
                ))
              : "Aucune commande n'a été trouvée pour cette demi-journée."}
          </>
        );

        return (
          <Accordion
            key={index}
            index={orderPickings.date}
            title={title}
            content={content}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        );
      })}
      <ModalCalendar
        isOpen={calendarOpening}
        setIsOpen={setCalendarOpening}
        onChange={(value) => {
          setAdditionalContractorsDate([
            ...additionalContractorsDate,
            {
              contractor_id: contractor.id.split("_-_")[0],
              round_line_position: parseInt(contractor.id.split("_-_")[1]),
              date: format(value, "yyyy-MM-dd"),
            },
          ]);
          setCalendarOpening(false);
        }}
        value={new Date()}
        usedDates={usedDates}
      />
    </div>
  );
};

PickingList.propTypes = {
  additionalContractorsDate: PropTypes.array.isRequired,
  contractor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      address: PropTypes.string.isRequired,
      postal_code: PropTypes.object.isRequired,
    }),
  }),
  currentPickingInfos: PropTypes.array.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  handleScan: PropTypes.func.isRequired,
  handleSuccessfulPickup: PropTypes.func.isRequired,
  handleTakePhoto: PropTypes.func.isRequired,
  resetPickupStatus: PropTypes.func.isRequired,
  setAdditionalContractorsDate: PropTypes.func.isRequired,
  updateOrderComment: PropTypes.func.isRequired,
  updatePickupIncidentReason: PropTypes.func.isRequired,
  usedDates: PropTypes.array.isRequired,
  setCurrentArticleMetadataPath: PropTypes.func.isRequired,
  onTakePhotoSuccessful: PropTypes.func.isRequired,
  startXscan: PropTypes.func.isRequired,
};

export default PickingList;
