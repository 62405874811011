import React from "react";
import PropTypes from "prop-types";
import Accordion from "components/Accordion";
import WriteCommentModal from "components/WriteCommentModal";
import PickingTable from "../PickingTable";
import { PICKUP_STATUS } from "../usePicking";

const OrderItem = ({
  order,
  handleScan,
  handleTakePhoto,
  handleDeletePhoto,
  activeOrderItem,
  setActiveOrderItem,
  resetPickupStatus,
  handleSuccessfulPickup,
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
  handleAddCommentToOrder,
  onTakePhotoSuccessful,
  setCurrentArticleMetadataPath,
  startXscan,
}) => {
  const [writeCommentModalOpen, setWriteCommentModalOpen] = React.useState(
    false
  );

  const allArticlesAreProcessed = order.order_articles.every((article) => {
    return article.metadata.every(
      (metadatum) => metadatum.pickup_status && metadatum.alreadySynchronized
    );
  });

  const getHeaderClassName = () => {
    const allArticlesArePickedUpFailed = order.order_articles.every((article) =>
      article.metadata.every(
        (metadatum) =>
          metadatum.pickup_status === PICKUP_STATUS.PICKUP_FAILED.value &&
          metadatum.alreadySynchronized
      )
    );
    const allArticlesArePickedUpSuccessfully = order.order_articles.every(
      (article) => {
        return article.metadata.every(
          (metadatum) =>
            metadatum.pickup_status === PICKUP_STATUS.PICKUP_OK.value &&
            metadatum.alreadySynchronized
        );
      }
    );
    const articlesArePickedUpPartially = !allArticlesAreProcessed
      ? order.order_articles.some((article) =>
          article.metadata.some(
            (metadatum) =>
              metadatum.pickup_status && metadatum.alreadySynchronized
          )
        )
      : !allArticlesArePickedUpFailed && !allArticlesArePickedUpSuccessfully;

    if (allArticlesArePickedUpFailed) {
      return "failed";
    }

    if (allArticlesArePickedUpSuccessfully) {
      return "completed";
    }

    if (articlesArePickedUpPartially) {
      return "partially";
    }

    return "";
  };

  return (
    <div className="order-item">
      <Accordion
        key={`order-${order.ref}`}
        headerClassName={getHeaderClassName()}
        title={
          <div className="order-item__title">
            <div>
              <b>Référence :</b> {order.ref}
            </div>
            <div>
              <b>Nom / Prénom :</b>{" "}
              {`${order?.client?.identity?.lastname} ${order?.client?.identity?.firstname}`}
            </div>
          </div>
        }
        content={
          <>
            {order.picking_comment && (
              <div className="order-item__comment">
                <b>Commentaire: </b> {order.picking_comment}
              </div>
            )}
            <div>{`Il vous reste ${
              order.maxNumberOfPhotos - order.numberOfPhotos || 0
            } sur ${order.maxNumberOfPhotos} photos.`}</div>
            <div className="order-item__articles">
              <PickingTable
                articles={order.order_articles}
                handleScan={handleScan}
                handleTakePhoto={handleTakePhoto}
                handleDeletePhoto={handleDeletePhoto}
                resetPickupStatus={resetPickupStatus}
                handleSuccessfulPickup={handleSuccessfulPickup}
                updatePickupIncidentReason={updatePickupIncidentReason}
                handleDamagedPackagingOrProductPhoto={
                  handleDamagedPackagingOrProductPhoto
                }
                canTakePhoto={
                  order.maxNumberOfPhotos - order.numberOfPhotos > 0
                }
                onTakePhotoSuccessful={onTakePhotoSuccessful}
                setCurrentArticleMetadataPath={setCurrentArticleMetadataPath}
              />
            </div>
            <div className="order-item__actions">
              {!allArticlesAreProcessed && (
                <button
                  className="write-comment-button"
                  onClick={() => setWriteCommentModalOpen(true)}
                >
                  {order.picking_comment
                    ? "Modifier le commentaire"
                    : "Rédiger un commentaire"}
                </button>
              )}
            </div>
          </>
        }
        activeItem={activeOrderItem}
        setActiveItem={(item) => {
          startXscan(order.order_articles);
          setActiveOrderItem(item)
        }}
        index={order.ref}
      />
      <WriteCommentModal
        isVisible={writeCommentModalOpen}
        setIsVisible={setWriteCommentModalOpen}
        comment={order.picking_comment}
        callback={(comment) => {
          handleAddCommentToOrder(order.path, comment);
          setWriteCommentModalOpen(false);
        }}
      />
    </div>
  );
};

OrderItem.propTypes = {
  order: PropTypes.shape({
    path: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
    picking_comment: PropTypes.string,
    maxNumberOfPhotos: PropTypes.number.isRequired,
    numberOfPhotos: PropTypes.number.isRequired,
    order_articles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        ean: PropTypes.string.ean,
      }).isRequired
    ).isRequired,
    client: PropTypes.shape({
      identity: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
      }),
    }),
    picking_status: PropTypes.string,
  }).isRequired,
  handleScan: PropTypes.func.isRequired,
  handleTakePhoto: PropTypes.func.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  activeOrderItem: PropTypes.string,
  setActiveOrderItem: PropTypes.func.isRequired,
  resetPickupStatus: PropTypes.func.isRequired,
  handleSuccessfulPickup: PropTypes.func.isRequired,
  updatePickupIncidentReason: PropTypes.func.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
  handleAddCommentToOrder: PropTypes.func.isRequired,
  onTakePhotoSuccessful: PropTypes.func.isRequired,
  setCurrentArticleMetadataPath: PropTypes.func.isRequired,
  startXscan: PropTypes.func.isRequired,
};

export default OrderItem;
