import React from "react";
import PropTypes from "prop-types";
import OrderItem from "../OrderItem";

const OrderPicking = ({
  order,
  handleScan,
  handleTakePhoto,
  handleDeletePhoto,
  resetPickupStatus,
  handleSuccessfulPickup,
  updatePickupIncidentReason,
  handleDamagedPackagingOrProductPhoto,
  updateOrderComment,
  onTakePhotoSuccessful,
  setCurrentArticleMetadataPath,
  startXscan,
}) => {
  const [activeOrderItem, setActiveOrderItem] = React.useState(order.ref);
  React.useEffect(() => {
    startXscan(order.order_articles);
  }, [order]); // eslint-disable-line

  return (
    <OrderItem
      key={order.id}
      order={order}
      handleScan={handleScan}
      handleTakePhoto={handleTakePhoto}
      handleDeletePhoto={handleDeletePhoto}
      activeOrderItem={activeOrderItem}
      setActiveOrderItem={setActiveOrderItem}
      resetPickupStatus={resetPickupStatus}
      handleSuccessfulPickup={handleSuccessfulPickup}
      updatePickupIncidentReason={updatePickupIncidentReason}
      handleDamagedPackagingOrProductPhoto={
        handleDamagedPackagingOrProductPhoto
      }
      handleAddCommentToOrder={updateOrderComment}
      onTakePhotoSuccessful={onTakePhotoSuccessful}
      setCurrentArticleMetadataPath={setCurrentArticleMetadataPath}
      startXscan={startXscan}
    />
  );
};

OrderPicking.propTypes = {
  order: PropTypes.object.isRequired,
  handleDamagedPackagingOrProductPhoto: PropTypes.func.isRequired,
  handleDeletePhoto: PropTypes.func.isRequired,
  handleScan: PropTypes.func.isRequired,
  handleSuccessfulPickup: PropTypes.func.isRequired,
  handleTakePhoto: PropTypes.func.isRequired,
  resetPickupStatus: PropTypes.func.isRequired,
  updateOrderComment: PropTypes.func.isRequired,
  updatePickupIncidentReason: PropTypes.func.isRequired,
  onTakePhotoSuccessful: PropTypes.func.isRequired,
  setCurrentArticleMetadataPath: PropTypes.func.isRequired,
  startXscan: PropTypes.func.isRequired,
};

export default OrderPicking;
