import React from "react";
import PropTypes from "prop-types";
import usePhoto from "utils/usePhoto";
import ButtonWithIcon from "components/ButtonWithIcon";

const PhotoInput = ({ open, setOpen, onTakePhotoSuccessful }) => {
  const videoRef = React.useRef();
  const { startVideo, stopVideo, takePhoto } = usePhoto(videoRef);
  const [photo, setPhoto] = React.useState(null);

  React.useEffect(() => {
    if (open) {
      startVideo();
    }
  }, [open, startVideo]);

  const handleTakePhoto = async () => {
    const base64photo = await takePhoto();
    setPhoto(base64photo);
  };

  const handleClose = () => {
    stopVideo();
    setOpen(false);
    setPhoto(null);
  };

  const handleSuccessCallback = () => {
    handleClose();
    onTakePhotoSuccessful(photo);
  };

  if (!open) {
    return null;
  }

  return (
    <div className="photo-input">
      <div className="photo-input-overlay">
        <div className="photo-input-overlay__close">
          <button
            className="photo-input-overlay__close-button"
            onClick={handleClose}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
        <div className="photo-input-overlay__media-container">
          <img alt="" src={photo} />
          <video ref={videoRef} />
        </div>
        <div className="photo-input-overlay__actions">
          {photo ? (
            <>
              <button onClick={handleSuccessCallback}>Valider</button>
              <button onClick={handleClose}>Annuler</button>
            </>
          ) : (
            <ButtonWithIcon onClick={handleTakePhoto} icon="camera">
              Prendre la photo
            </ButtonWithIcon>
          )}
        </div>
      </div>
    </div>
  );
};

PhotoInput.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onTakePhotoSuccessful: PropTypes.func.isRequired,
};

export default PhotoInput;
