import { useContext, createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const KEY = "CURRENT-USER";

const isLocalStorageAvailable = () => {
  return window && window.localStorage;
};

const guardAgainstUnavailableLocalStorage = (callback) => {
  if (!isLocalStorageAvailable()) {
    throw new Error("The localStorage is not available");
  }

  return callback;
};

const getPersistentUser = guardAgainstUnavailableLocalStorage(() => {
  return JSON.parse(window.localStorage.getItem(KEY) || null);
});

const setPersistentUser = guardAgainstUnavailableLocalStorage((newCode) => {
  return window.localStorage.setItem(KEY, JSON.stringify(newCode));
});

const removePersistentUser = guardAgainstUnavailableLocalStorage(() => {
  return window.localStorage.removeItem(KEY);
});

const removePersistentAdditionalContractorsDate = guardAgainstUnavailableLocalStorage(
  () => {
    return window.localStorage.removeItem("ADDITIONAL-DATES");
  }
);

const AuthContext = createContext({
  loginCode: null,
  setLoginCode: () => {},
});

const useAuthState = () => {
  return useState(getPersistentUser);
};

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      // Replace space with 'T' to ensure ISO 8601 format for cross-browser compatibility
      const loginDateString = user.login_at.replace(" ", "T");
      const tokenDate = new Date(loginDateString);
      const today = new Date();

      if (today.toDateString() !== tokenDate.toDateString()) {
        setUser(null);
        removePersistentUser();
        history.push("/");
      }
    }
  }, [user, setUser, history]);

  const login = (userData) => {
    setUser(userData);
    setPersistentUser(userData);
  };

  const logout = () => {
    setUser(null);
    removePersistentUser();
    removePersistentAdditionalContractorsDate();
  };

  return {
    user,
    login,
    logout,
  };
};

export { AuthContext, useAuthState };

export default useAuth;
