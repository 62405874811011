import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// Register service worker for offline functionality
serviceWorkerRegistration.register();

// Initialize notifications
const initNotifications = () => {
  // Check if we're in a browser environment
  if (typeof window === "undefined") return;

  // Check for Notification API support
  if ("Notification" in window) {
    Notification.requestPermission();
  }
};

// Initialize notifications after service worker registration
initNotifications();

// Report web vitals
reportWebVitals();
