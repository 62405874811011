import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BrowserMultiFormatReader } from '@zxing/library';
import { PICKUP_INCIDENTS } from "pages/Picking/usePicking";
import { turnOnTorchLight } from "utils/torchlight";

const bip = new Audio("/sounds/bip.mp3");

const BarcodeScanner = React.memo(({
  open,
  setOpen,
  target = "",
  onSuccessfulScan,
  onAbortScan,
}) => {
  const [error, setError] = React.useState(null);
  const videoRef = React.useRef(null);
  const readerRef = React.useRef(null);
  const streamRef = React.useRef(null);

  // Réinitialiser l'erreur quand un nouveau target est reçu
  React.useEffect(() => {
    if (target) {
      setError(null);
    }
  }, [target]);

  const handleClose = React.useCallback(() => {
    if (readerRef.current) {
      readerRef.current.reset();
      readerRef.current.stopAsyncDecode();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => {
        if (track.kind === 'video') {
          const capabilities = track.getCapabilities();
          if (capabilities.torch) {
            track.applyConstraints({
              advanced: [{ torch: false }]
            });
          }
        }
        track.stop();
      });
    }
    setOpen(false);
    setError(null);
  }, [setOpen]);

  const handleAbortScan = React.useCallback((success, reason) => {
    onAbortScan(success, reason);
    handleClose();
  }, [onAbortScan, handleClose]);

  const handleScan = React.useCallback((scannedCode) => {
    if (!target) {
      return; // Ignorer le scan si pas de target
    }
    if (scannedCode === target) {
      bip.play();
      handleClose();
      onSuccessfulScan(scannedCode);
    } else {
      setError(`Code attendu: ${target}, Code scanné: ${scannedCode}`);
    }
  }, [target, onSuccessfulScan, handleClose]);

  React.useEffect(() => {
    if (open && videoRef.current) {
      const startScanner = async () => {
        try {
          readerRef.current = new BrowserMultiFormatReader();
          
          // Récupérer la caméra arrière si disponible
          const devices = await navigator.mediaDevices.enumerateDevices();
          const videoDevices = devices.filter(device => device.kind === 'videoinput');
          const rearCamera = videoDevices.find(device => 
            device.label.toLowerCase().includes('back') || 
            device.label.toLowerCase().includes('arrière') ||
            device.label.toLowerCase().includes('rear')
          );

          const constraints = {
            video: {
              facingMode: 'environment',
              deviceId: rearCamera ? { exact: rearCamera.deviceId } : undefined
            }
          };

          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          streamRef.current = stream;
          
          // Allumer le flash
          await turnOnTorchLight(stream);

          await readerRef.current.decodeFromConstraints(
            constraints,
            videoRef.current,
            (result) => {
              if (result) {
                handleScan(result.getText());
              }
            }
          );
        } catch (err) {
          console.error('Erreur scanner:', err);
          setError("Impossible d'accéder à la caméra. Vérifiez les permissions.");
        }
      };

      startScanner();

      return () => {
        if (readerRef.current) {
          readerRef.current.reset();
          readerRef.current.stopAsyncDecode();
        }
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
        }
      };
    }
  }, [open, handleScan]);

  if (!open) return null;

  return (
    <div className={classNames("barcode-scanner", { "barcode-scanner--visible": open })}>
      <div className="scan-overlay">
        <div className="scan-overlay__close">
          <button className="scan-overlay__close-button" onClick={handleClose}>
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>

        <div className="scan-overlay__target">
          <b>Code barre à scanner</b> : {target}
        </div>

        <div className="scan-overlay__video-container">
          <video
            ref={videoRef}
            className="scan-overlay__video"
            playsInline
          />
          <div className="scan-overlay__targeting-box" />
        </div>
        <div className="scan-overlay__actions">
          {error ? (
            <button
              className="scan-overlay__retry-button"
              onClick={() => {
                setError(null);
              }}
            >
              Réessayer
            </button>
          ) : (
            <>
              <button onClick={() => handleAbortScan(true, PICKUP_INCIDENTS.FORCED.value)}>
                Forcer
              </button>
              <button onClick={() => handleAbortScan(true, PICKUP_INCIDENTS.EXHIBITION_PRODUCT.value)}>
                Produit d&apos;exposition
              </button>
              <button onClick={() => handleAbortScan(false, PICKUP_INCIDENTS.PRODUCT_OR_EAN_DOES_NOT_MATCH.value)}>
                Mauvais produit
              </button>
            </>
          )}
        </div>

        {error && (
          <div className="scan-overlay__error">
            {error}
          </div>
        )}
      </div>
    </div>
  );
});

BarcodeScanner.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  target: PropTypes.string,
  onSuccessfulScan: PropTypes.func.isRequired,
  onAbortScan: PropTypes.func.isRequired,
};

BarcodeScanner.displayName = 'BarcodeScanner';

export default BarcodeScanner;
